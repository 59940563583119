import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { PortableText } from "@portabletext/react";

import SearchEngineOptimization from "../components/utility/SearchEngineOptimization";
import AboutCard from "../components/cards/AboutCardPortrait";

import components from "../utils/componentsForPortableText";
import formatDynamicAges from "../utils/formatDynamicAges";
import sanityConnectInfo from "../../sanityConnectInfo.json";
import SupportBand from "../components/general/SupportBand";

const portableTextComponents = {
  ...components,

  block: {
    normal: ({ children }) => {
      let output = [...children];
      output.forEach((_, i) => {
        if (typeof output[i] === "string") {
          formatDynamicAges(output[i]);
        }
      });

      return <p>{output}</p>;
    },
  },
};

const OurStoryStyles = styled.div`
  .content {
    padding-left: 10%;
    padding-right: 10%;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr;
    }

    .intro {
      margin-bottom: 3rem;
      display: grid;

      h1 {
        text-align: center;
      }

      h2 {
        margin: 0;
      }

      img {
        justify-self: center;
        margin-bottom: 2rem;
      }
    }

    .signup {
      img {
        width: 100%;
        max-width: 100%;
      }
    }
  }
`;

export function Head({ data: { page }, location, ...props }) {
  return (
    <SearchEngineOptimization
      {...props}
      meta={{
        title: page.metaData.metaTitle,
        description: page.metaData.metaDescription,
        image: page.metaData.metaImage.image.asset.gatsbyImageData,
        alt: page.metaData.metaImage.alt,
        createdAt: page._createdAt,
        updatedAt: page._updatedAt,
      }}
      location={location}
    />
  );
}

export default function OurStoryPage({
  data: { page, about, paymentGateways },
  location,
}) {
  return (
    <OurStoryStyles>
      <div className="content">
        <div className="intro">
          <h1>{page.title}</h1>

          <PortableText
            value={page.textEditor._rawRichText}
            projectId={sanityConnectInfo.projectId}
            dataset={sanityConnectInfo.dataset}
            components={portableTextComponents}
          />
        </div>

        <div className="bottom-about">
          <AboutCard about={about} />
        </div>

        <div className="bottom-support-band">
          <SupportBand support={paymentGateways} />
        </div>
      </div>
    </OurStoryStyles>
  );
}

export const query = graphql`
  query {
    page: sanityPage(slug: { current: { eq: "our-story" } }) {
      _createdAt
      _updatedAt
      title
      slug {
        current
      }
      textEditor {
        _rawRichText(resolveReferences: { maxDepth: 10 })
      }
      metaData {
        metaTitle
        metaDescription
        robotsContent
        metaImage {
          alt
          image {
            asset {
              gatsbyImageData(
                height: 400
                layout: CONSTRAINED
                width: 400
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }

    about: sanitySection(title: { eq: "About Family I Love" }) {
      textEditor {
        _key
        _type
        _rawRichText(resolveReferences: { maxDepth: 10 })
      }
    }

    paymentGateways: sanitySection(title: { eq: "Support The Band" }) {
      textEditor {
        _rawRichText(resolveReferences: { maxDepth: 10 })
      }
      title
    }
  }
`;
